.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.logo {
  
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  flex-grow: 0;
}
.conten-card {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.conten-card-llanta {
  
  align-items: center;
}

.logo-llanta {
  height: 86px;

  border-radius: 10px;  
}

.llanta-repuesto {
  width: 95px;
  height: 95px;
  border-radius: 10px;  
}

.llanta-temporal {
  max-height: 50px;


  border-radius: 50px;  
}

div.conten-card-llanta > span{
  position: relative;
  display:none;
}

div.conten-card-llanta:hover > span{
  position: absolute;
  font-size: small;
  top:-10px;
  left:45px;
  display:block;
  background:#1e4ed2;
  color:white;
  padding:6px;
  border-radius: 5px;
  z-index: 1000;
}

div.conten-card-llanta:hover > span:after {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-right-color: #1e4ed2;
	border-width: 6px;
	margin-top: -6px;
}